














































import { Component, Vue } from 'vue-property-decorator';
import {
  dispatchGetOrigemLocalidadeLista,
  dispatchUpdateOrigemLocalidade,
} from '@/store/origem_localidade/actions';
import { consultarOrigemLocalidade } from '@/store/origem_localidade/getters';
import { IOrigemLocalidadeUpdate } from '@/interfaces/origem_localidade';

@Component
export default class AlterarOrigemLocalidade extends Vue {
  public valid = true;
  public nome: string = '';
  public sigla: string = '';

  public async mounted() {
    await dispatchGetOrigemLocalidadeLista(this.$store);
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    if (this.origemLocalidade) {
      this.nome = this.origemLocalidade.nome;
      this.sigla = this.origemLocalidade.sigla;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const origemLocalidadeAtualizada: IOrigemLocalidadeUpdate = {
        nome: this.nome,
        sigla: this.sigla,
      };

      const atualizou = await dispatchUpdateOrigemLocalidade(this.$store, {
        id: this.origemLocalidade!.id,
        origemLocalidade: origemLocalidadeAtualizada,
      });
      if (atualizou) {
        this.$router.push('/main/origem_localidade/listar');
      }
    }
  }

  get origemLocalidade() {
    return consultarOrigemLocalidade(this.$store)(+this.$router.currentRoute.params.id);
  }
}
